.video {
    position: relative;
    width: 100%;
    height: 0;
  }
  
  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }