.navigation-header {
    background-color: #22272e;
    padding: 12px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  html{
    scroll-behavior: smooth;
  }
  .navigation-header a.Link {
    color: rgb(200, 200, 200);
    text-align: center;
    padding: 2px 16px;
    font-size: 2vw;
    text-decoration: none;
    font-family: "Light";
    transition: color 0.3s ease;
  }
  
  .navigation-header a.Link:hover {
    color: #fff;
  }
  
  .navbar-selection {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .navbar-selection:hover {
    background-color: #555;
  }
  
  .navigation-header a.Title {
    color: white;
    text-align: center;
    /*padding: 14px 16px;*/
    font-size: 2vw;
    font-weight: bold;
    text-decoration: none;
    font-family: "Medium";
    transition: color 0.3s ease;
  }
  
  .navigation-header a.Title:hover {
    color: #ccc;
  }
  
  .menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    transition: transform 0.3s ease;
  }
  
  .menu-icon:hover {
    transform: scale(1.2);
  }
  
  .menu-icon span {
    display: block;
    width: 24px;
    height: 3px;
    background-color: #777;
    margin-bottom: 4px;
    transition: transform 0.3s ease;
  }
  
  .menu-open .menu-icon span {
    transform: rotate(45deg);
  }
  
  .menu-items {
    display: flex;
    flex-direction: row;
  }
  
  .menu-items.open {
    display: flex;
    flex-direction: column;
  }
  
  @media (max-width: 600px) {
    .menu-items {
      display: none;
    }
  
    .menu-open .menu-items {
      display: flex;
      flex-direction: column;
    }
  
    .menu-items .navbar-selection {
      padding-left: 0;
      padding-right: 0;
      margin: 10px;
      text-align: center;
      background-color: #22272e;
      border-radius: 5px;
      transition: background-color 0.3s ease;
      font-size: 5vw; /* Set font size to 5vw on mobile */
    }
  
    .menu-items .navbar-selection:hover {
      background-color: #555;
    }
  }
  
  @media (min-width: 601px) {
    .navigation-header a.Link,
    .navigation-header a.Title {
      font-size: 2vw; /* Set font size to 2vw on PC */
    }
  }