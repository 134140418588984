
 .animated-text {
    color: #f1f1f1;
    font-weight: 400;
    font-size: 7vw;
    letter-spacing: 0px;
    line-height: 1.2;
    animation: wordReplacement 10s ease-in-out infinite;
    display: block;
    position:relative;
    overflow: hidden;
    font-family: Medium;
    left: 10vw;
}
.animated-container{
    position:relative;
    width: 50vw;
    height:8.4vw;
    overflow: hidden;
}
@keyframes wordReplacement{
    
    0% {
        top: 0vw;
        width: 0;
      }
      5% {
        width: 0;
      }
      12% {
        width: 50vw;
      }
      18% {
        top: 0vw;
        width: 50vw;
      }
      21% {
        top: 0vw;
        width: 0;
      }
      25% {
        top: -8.4vw;
        width: 0;
      }
      29% {
        top: -8.4vw;
        
      }
      37% {
        top: -8.4vw;
        width: 50vw;
      }
      42% {
        top: -8.4vw;
        width: 50vw;
      }
      50% {
        top: -8.4vw;
        width: 0;
        
      }
      55% {
        top: -16.8vw;
        width: 0;
        
      }
      62% {
        top: -16.8vw;
        width: 50vw;
      }
      69% {
        top: -16.8vw;
        width: 50vw;
      }
      75% {
        top: -16.8vw;
        width: 0;
        
      }
      80% {
        top: -25.2vw;
        width: 0;
        
      } 
      87% {
        top: -25.2vw;
        width: 50vw;
      }
      93%{
        top: -25.2vw;
        width: 50vw;
      }
      100% {
        top: -25.2vw;
        width: 0;
        
      }
}
