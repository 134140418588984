.card img {
    width : 200px;
    height : 200px;
    border-radius: 50%;
    border-color: blue;
    border-width: 2px;
    border-style:solid;
    box-shadow: 0 0 10px rgba(0,0,255,0.5);
}
.card .name{
    padding: 5px;
}
.card a{
    padding: 1px;
}
.card{
    text-align: center;
    line-height: 0.6;
    padding: 10px;
}
.grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 7px;
    
  }
  
.grid-item {
    border-radius: 2vw;
    border: 1px solid black;
    padding: 10px;
}