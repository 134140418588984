body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Bold";  
  src: local("RobotoMono-Bold"),
    url("./fonts/static/RobotoMono-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Italic";  
  src: local("RobotoMono-Italic"),
    url("./fonts/static/RobotoMono-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Light";  
  src: local("RobotoMono-Light"),
    url("./fonts/static/RobotoMono-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Medium";  
  src: local("RobotoMono-Medium"),
    url("./fonts/static/RobotoMono-Medium.ttf") format("truetype");
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
